import React from 'react'
import { graphql, Link } from 'gatsby'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"


const BlogListPage = ({ data, pageContext }) => (
  <>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Blog | Skillmeter</title>
  </Helmet>    

  <Header />

  <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="px-8 mx-auto text-center md:px-0">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl">
            Skillmeter Blog
          </h2>
          <br />
          <Link to='/blog/guest-posts' className='text-gray-400 font-bold hover:text-gray-600'>Want to contribute?</Link>

        </div>

        <div className='max-w-5xl mx-auto mt-10 sm:mt-20'>

          {data.posts.edges.map((edge, index) => {
          //console.log(edge.node.title);
          return (
            
            <div class="mt-4 mb-8 sm:mt-0 sm:mb-16 sm:ml-10" key={index}>
                <p class="text-2xl font-bold text-black">
                <Link to={`/blog/${edge.node.url}`} title={edge.node.title} className='text-black hover:text-gray-600'>
                {edge.node.title}
                </Link>
                </p>

                <div className="mt-8 text-black" dangerouslySetInnerHTML={{ __html: edge.node.description.description}}>
                </div>              

              </div>        
            
          )
          })}

      </div>

      <div className="flex justify-center mt-10">
        {pageContext && pageContext.hasPrevPage && (
            <Link className="inline-flex items-center justify-center px-5 py-2 font-bold leading-7 text-white bg-smcolor border border-transparent rounded-xl hover:bg-smcolor-dark focus:outline-none  mr-4" to={pageContext.prevPageLink}>
              &#8592; Prev page 
            </Link>
          )}

        {pageContext && pageContext.hasNextPage && (
            <Link className="inline-flex items-center justify-center px-5 py-2 font-bold leading-7 text-white bg-smcolor border border-transparent rounded-xl hover:bg-smcolor-dark focus:outline-none  " to={pageContext.nextPageLink}>
              Next page &#8594;
            </Link>
          )}

      </div>

      </div>

      <div>


  </div>

  </section>


  <Footer />

  </>
)

export default BlogListPage

export const pageQuery = graphql`
fragment PostTeaserFragment on ContentfulSkillmeterBlogPost {
    url
    title
    description {
      description
    }
    body {body}
    publishedOn

  }

  query BlogListPageQuery($skip: Int, $limit: Int) {
    posts: allContentfulSkillmeterBlogPost(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: [publishedOn] }
    ) {
      edges {
        node {
          ...PostTeaserFragment
        }
      }
    }
  }
`